/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

// import "./style.css";
import styles from "./index.module.scss";
import {
  Project,
  ReportSlideEntry,
  SlideSet,
} from "src/models/shared/projects";

import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { ReactComponent as TrashIcon } from "../../icons/iconamoon--trash.svg";

import { InlineEditableTextfield } from "@atlaskit/inline-edit";
import Select, { SingleValue } from "react-select";
import { useProjectUploadCustomSlide } from "src/hooks/use-project-upload-custom-slide";
import { ChangeEvent, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "drag-n-drop-grid";
import useMeasure from "react-use-measure";

// Globals.skipAnimation = true;

type OptionType = { value: SlideSet; label: string };

interface ItemsType {
  [key: string]: ReportSlideEntry[];
}

interface Props {
  project: Project;
  onSlideSetsChanged: (project: Project, refresh: boolean) => Promise<void>;
  onCustomSlidesChanged: (project: Project) => Promise<void>;
}

export const ProjectSlides = ({
  project,
  onSlideSetsChanged,
  onCustomSlidesChanged,
}: Props): JSX.Element => {
  const [ref, bounds] = useMeasure();
  const { mutateAsync: invokeProjectUploadCustomSlide } =
    useProjectUploadCustomSlide();

  const [isEditingSlideSetName, setIsEditingSlideSetName] =
    useState<boolean>(false);

  // because saving project (e.g. save changed value) causes a full refresh, we need to persist the state of the selected slideSet Id "elsewhere" as long as the project id has not changed.
  // once project id changed, default again to null
  if (localStorage.getItem("project-slides-project-id") !== project._id) {
    localStorage.setItem("project-slides-slideSet-id", "");
  }
  const [slideSetId, setSlideSetId] = useState<string>(
    localStorage.getItem("project-slides-slideSet-id") || ""
  );

  localStorage.setItem("project-slides-project-id", project._id || "null");
  useEffect(() => {
    localStorage.setItem("project-slides-slideSet-id", slideSetId);
  }, [slideSetId]);

  // console.log(`bounds:`, bounds);
  const totalBoxesPerRow = Math.max(2, Math.floor(bounds.width / 140));
  // console.log(`totalBoxesPerRow: ${totalBoxesPerRow}`);

  const newSlideSetClicked = async () => {
    if (project.slideSets == null) {
      project.slideSets = [];
    }
    const newSlideSetId = uuidv4();
    console.log("newSlideSetId", newSlideSetId);
    project.slideSets.push({
      _id: newSlideSetId,
      name: "New Slide Set",
      slides: [...defaultSlideSetEntries],
    });
    setSlideSetId(newSlideSetId);
    setItems(getItemsFromProjectUsingSlideSet(getSlideSet(newSlideSetId)));
    await onSlideSetsChanged(project, true);
  };

  const onSelectedSlideSetChanged = (newValue: SingleValue<OptionType>) => {
    const newSlideSetId = newValue!.value._id;
    setSlideSetId(newSlideSetId);
    setItems(getItemsFromProjectUsingSlideSet(getSlideSet(newSlideSetId)));
  };

  const slideSetRenamed = async (value: string) => {
    getSlideSet(slideSetId)!.name = value;
    await onSlideSetsChanged(project, true);
    setIsEditingSlideSetName(false);
  };

  const deleteCustomSlideClicked = async (
    slideId: string,
    slideName: string
  ) => {
    if (
      window.confirm(
        `are you sure you want to delete this custom slide? (${slideName})?\n\nNote that if this custom slide is in use by any other slide set it will be removed there as well.\n\nThis action cannot be undone. `
      )
    ) {
      project.slideSets.forEach((ss) => {
        ss.slides = ss.slides.filter(
          (s) => (s.slideSource === "custom" && s.slideId === slideId) === false
        );
      });
      project.customSlides = project.customSlides.filter(
        (cs) => cs._id !== slideId
      );
      await onSlideSetsChanged(project, false);
      await onCustomSlidesChanged(project);
    }
  };

  const newSlideClicked = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const updatedProject = await invokeProjectUploadCustomSlide({
        file,
        project,
      });
      if (updatedProject) {
        await onCustomSlidesChanged(updatedProject);
      }
      event.target.value = ""; // to reset the input field so same file can fire onChange again
    }
  };

  const getItemsFromProjectUsingSlideSet = (slideSet: SlideSet | null) => {
    return {
      left: slideSet?.slides || [],
      right: [
        ...defaultSlideSetEntries.filter(
          (se) =>
            (slideSet?.slides || []).findIndex(
              (s) => s.slideId === se.slideId
            ) < 0
        ),
        ...(project.customSlides || [])
          .filter(
            (cs) =>
              (slideSet?.slides || []).findIndex((s) => s.slideId === cs._id) <
              0
          )
          .map((cs) => {
            return { slideSource: "custom", slideId: cs._id };
          }),
      ],
    } as ItemsType;
  };

  const getDraggableSlideElement = (
    item: ReportSlideEntry,
    index: number,
    project: Project
  ) => {
    if (
      item.slideSource === "custom" &&
      project.customSlides.findIndex((cs) => cs._id === item.slideId) < 0
    ) {
      return null;
    }

    const label = getSlideEntryLabel(item, project);

    return (
      <GridItem key={item.slideId}>
        <div className={styles["grid-item"]}>
          <div className={styles["grid-item-content"]}>
            <img
              className={styles["grid-item-image"]}
              alt={item.slideId}
              src={getSlideThumbnail(item, project)}
            ></img>
            <div className={styles["grid-item-label"]}>{label}</div>
            <div className={styles["grid-item-index"]}>{index + 1}</div>
            {item.slideSource === "custom" && (
              <div className={styles["grid-item-type-container"]}>
                <div className={styles["grid-item-type-label"]}>custom</div>
                <div className={styles["grid-item-type-spacer"]}></div>
                <div
                  className={styles["grid-item-type-action-delete"]}
                  title="delete custom slide"
                  onClick={() => {
                    deleteCustomSlideClicked(item.slideId, label);
                  }}
                >
                  <TrashIcon />
                </div>
              </div>
            )}
          </div>
        </div>
      </GridItem>
    );
  };

  const getSlideSet = (id?: string) => {
    return project.slideSets
      ? id
        ? project.slideSets.find((ss) => ss._id === id) || project.slideSets[0]
        : project.slideSets[0]
      : null;
  };

  const [items, setItems] = useState<ItemsType>(
    getItemsFromProjectUsingSlideSet(getSlideSet(slideSetId))
  );

  const onGridChange = async (
    sourceId: string | undefined,
    sourceIndex: number,
    targetIndex: number,
    targetId: string | undefined
  ) => {
    console.log(
      `onGridChange(sourceId: ${sourceId}, sourceIndex: ${sourceIndex}, targetId: ${targetId}, targetIndex: ${targetIndex})`
    );

    if (sourceId) {
      let newItems: ItemsType;
      if (targetId) {
        const result = move(
          items[sourceId],
          items[targetId],
          sourceIndex,
          targetIndex
        );
        newItems = {
          ...items,
          [sourceId]: result[0],
          [targetId]: result[1],
        };
      } else {
        const result = swap(items[sourceId], sourceIndex, targetIndex);
        newItems = {
          ...items,
          [sourceId]: result,
        };
      }
      setItems(newItems);

      console.log(
        "about to update slides to:",
        newItems["left"],
        newItems.left.length
      );

      getSlideSet(slideSetId)!.slides = [...newItems["left"]];
      await onSlideSetsChanged(project, false);
    }

    /*const source = sourceId === "left" ? slideSet!.slides : unusedSlides;
    if (targetId) {
      const target = targetId === "left" ? slideSet!.slides : unusedSlides;

      const result = move(source, target, sourceIndex, targetIndex);
      //slideSet!.slides = sourceId === "left" ? result[0] : result[1];
      setSlides(sourceId === "left" ? result[0] : result[1]);
      setUnusedSlides(sourceId === "left" ? result[1] : result[0]);
      //await onSlideSetsChanged(project);
    } else {
      if (sourceId === "left") {
        //slideSet!.slides = swap(source, sourceIndex, targetIndex);
        setSlides(swap(source, sourceIndex, targetIndex));
        // await onSlideSetsChanged(project);
      } else {
        setUnusedSlides(swap(unusedSlides, sourceIndex, targetIndex));
      }
    }*/
  };

  const leftSlideCount = items["left"].length;
  const rightSlideCount = items["right"].length;
  const boxesPerRowLeft = Math.min(
    totalBoxesPerRow - 1,
    Math.ceil(
      (totalBoxesPerRow * leftSlideCount) / (leftSlideCount + rightSlideCount)
    )
  );
  const boxesPerRowRight = totalBoxesPerRow - boxesPerRowLeft;
  const rowHeight = 110;
  const dropZoneHeight =
    rowHeight *
    Math.max(
      Math.ceil(leftSlideCount / boxesPerRowLeft),
      Math.ceil(rightSlideCount / boxesPerRowRight)
    );

  const slideSetOptions: OptionType[] = (project.slideSets || []).map((s) => ({
    value: s,
    label: s.name,
  }));

  console.log("slideSetId:", slideSetId);
  const slideSet = getSlideSet(slideSetId);
  return (
    <div className={styles["project-slides-container"]} ref={ref}>
      <div className={styles["project-slides-title-row"]}>
        <div className={styles["project-slides-title"]}>Slide Set:</div>
        <div className={styles["project-slide-set-selection"]}>
          {project.slideSets && project.slideSets.length > 0 ? (
            <div>
              {isEditingSlideSetName ? (
                <InlineEditableTextfield
                  placeholder="Slide Set Name"
                  defaultValue={slideSet?.name}
                  startWithEditViewOpen={true}
                  onConfirm={slideSetRenamed}
                  onCancel={() => {
                    setIsEditingSlideSetName(false);
                  }}
                ></InlineEditableTextfield>
              ) : (
                <Select
                  isSearchable={false}
                  captureMenuScroll={true}
                  options={slideSetOptions}
                  defaultValue={
                    { value: slideSet, label: slideSet?.name } as OptionType
                  }
                  onChange={onSelectedSlideSetChanged}
                />
              )}
            </div>
          ) : (
            <div>This project has no custom slide sets</div>
          )}
        </div>
        {slideSet && isEditingSlideSetName === false && (
          <div
            className={styles["rename-slide-set-button"]}
            onClick={() => {
              setIsEditingSlideSetName(true);
            }}
          >
            <EditIcon />
          </div>
        )}
        {slideSet && (
          <div
            className={styles["delete-slide-set-button"]}
            onClick={async () => {
              if (
                window.confirm(
                  `are you sure you want to delete this slide set (${
                    slideSet!.name
                  })? This action cannot be undone.`
                )
              ) {
                project.slideSets.splice(
                  project.slideSets.findIndex((s) => s._id === slideSet._id),
                  1
                );
                await onSlideSetsChanged(project, true);
                setSlideSetId("");
              }
            }}
          >
            <TrashIcon />
          </div>
        )}

        <div
          className={styles["new-slide-set-button"]}
          onClick={() => newSlideSetClicked()}
        >
          New Slide Set
        </div>

        <label className={styles["new-slide-button"]}>
          <input type="file" accept=".pdf" onChange={newSlideClicked} />
          Upload New Custom Slide
        </label>
      </div>
      <div className={styles["project-slides"]}>
        {slideSet?.slides && (
          <GridContextProvider onChange={onGridChange}>
            <div className={styles["dnd-scroll-container"]}>
              <div className={styles["dnd-container"]}>
                <GridDropZone
                  className={`${styles["dropzone"]} ${styles["left"]}`}
                  style={{ flex: boxesPerRowLeft, height: dropZoneHeight }}
                  id="left"
                  boxesPerRow={boxesPerRowLeft}
                  rowHeight={rowHeight}
                >
                  {items["left"].map((item, index) =>
                    getDraggableSlideElement(item, index, project)
                  )}
                </GridDropZone>
                <GridDropZone
                  className={`${styles["dropzone"]} ${styles["right"]}`}
                  style={{ flex: boxesPerRowRight, height: dropZoneHeight }}
                  id="right"
                  boxesPerRow={boxesPerRowRight}
                  rowHeight={rowHeight}
                >
                  {items["right"].map((item, index) =>
                    getDraggableSlideElement(item, index, project)
                  )}
                </GridDropZone>
              </div>
            </div>
          </GridContextProvider>
        )}
      </div>
    </div>
  );
};

function getSlideEntryLabel(s: ReportSlideEntry, project: Project) {
  let baseString = "";
  if (s.slideSource === "custom") {
    baseString =
      project.customSlides
        .find((cs) => cs._id === s.slideId)
        ?.name.replace(/\.pdf$/gi, "") || "[unnamed]";
  } else {
    baseString = s.slideId;
  }
  return baseString
    .replace(/[.\-_]+/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2");
}

function getSlideThumbnail(slideSetEntry: ReportSlideEntry, project: Project) {
  const baseUrl = process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL;
  let thumbPath = null;
  if (slideSetEntry.slideSource === "custom") {
    const customSlide = project.customSlides.find(
      (s) => s._id === slideSetEntry.slideId
    );
    if (customSlide) {
      thumbPath = customSlide.thumbnail;
    }
  } else {
    let page = -1;
    switch (slideSetEntry.slideId) {
      case "cover":
        page = 1;
        break;
      case "4Cs-of-corporate-culture":
        page = 2;
        break;
      case "4Cs-cultures-described":
        page = 3;
        break;
      case "4-pillars-of-a-corporate-culture":
        page = 4;
        break;
      case "4Cs-leadership-described":
        page = 5;
        break;
      case "Hogan-personality-traits":
        page = 6;
        break;
      case "CLIENT-Fit-potential-spikes":
        page = 7;
        break;
      case "Definitions-of-STRENGTHS":
        page = 8;
        break;
      case "STRENGTHS-of-leaders":
        page = 9;
        break;
      case "Definitions-of-CHALLENGES":
        page = 10;
        break;
      case "CHALLENGES-of-leaders":
        page = 11;
        break;
      case "Definitions-of-MOTIVATIONS":
        page = 12;
        break;
      case "MOTIVATIONS-of-leaders":
        page = 13;
        break;
      case "traitScores.Strength":
        page = 14;
        break;
      case "traitScoreDetails.Strength":
        page = 15;
        break;
      case "traitScores.Challenge":
        page = 17;
        break;
      case "traitScoreDetails.Challenge":
        page = 18;
        break;
      case "traitScores.Motivation":
        page = 21;
        break;
      case "traitScoreDetails.Motivation":
        page = 22;
        break;
      case "compass.allSpikes":
        page = 25;
        break;
      case "compass.customValues.individual":
        page = 26;
        break;
      case "compass.customValues.allTraits":
        page = 31;
        break;
      case "compass.customValues.allSpikes":
        page = 32;
        break;
      case "fitPerCustomValue":
        page = 32.5;
        break;
      case "culturalDimensions":
        page = 33;
        break;
      case "scoreCard":
        page = 34;
        break;
      case "attracting-and-assessing-key-talent":
        page = 35;
        break;
      case "global-footprint":
        page = 36;
        break;
      case "consultants":
        page = 37;
        break;
      case "break-slide:bringing-organizations":
        page = 40;
        break;
      case "our-values":
        page = 41;
        break;
    }
    if (page >= 0) {
      thumbPath = `thumbnails/generic-report/generic-report-${
        page < 10 ? `0${page}` : page
      }.jpg`;
    }
  }
  if (thumbPath == null) {
    thumbPath = "thumbnails/generic-report/not-found.jpg";
  }
  return `${baseUrl}/${thumbPath}`;
}

const defaultSlideSetEntries: ReportSlideEntry[] = [
  { slideSource: "dynamic", slideId: "cover" },
  { slideSource: "static", slideId: "4Cs-of-corporate-culture" },
  { slideSource: "static", slideId: "4Cs-cultures-described" },
  { slideSource: "static", slideId: "4-pillars-of-a-corporate-culture" },
  { slideSource: "static", slideId: "4Cs-leadership-described" },
  { slideSource: "static", slideId: "Hogan-personality-traits" },
  { slideSource: "static", slideId: "CLIENT-Fit-potential-spikes" },

  { slideSource: "static", slideId: "Definitions-of-STRENGTHS" },
  { slideSource: "static", slideId: "STRENGTHS-of-leaders" },
  { slideSource: "static", slideId: "Definitions-of-CHALLENGES" },
  { slideSource: "static", slideId: "CHALLENGES-of-leaders" },
  { slideSource: "static", slideId: "Definitions-of-MOTIVATIONS" },
  { slideSource: "static", slideId: "MOTIVATIONS-of-leaders" },

  { slideSource: "dynamic", slideId: "traitScores.Strength" },
  { slideSource: "dynamic", slideId: "traitScoreDetails.Strength" },
  { slideSource: "dynamic", slideId: "traitScores.Challenge" },
  { slideSource: "dynamic", slideId: "traitScoreDetails.Challenge" },
  { slideSource: "dynamic", slideId: "traitScores.Motivation" },
  { slideSource: "dynamic", slideId: "traitScoreDetails.Motivation" },

  { slideSource: "dynamic", slideId: "compass.allSpikes" },
  { slideSource: "dynamic", slideId: "compass.customValues.individual" },
  { slideSource: "dynamic", slideId: "compass.customValues.allTraits" },
  { slideSource: "dynamic", slideId: "compass.customValues.allSpikes" },
  { slideSource: "dynamic", slideId: "fitPerCustomValue" },
  { slideSource: "dynamic", slideId: "culturalDimensions" },
  { slideSource: "dynamic", slideId: "scoreCard" },

  {
    slideSource: "static",
    slideId: "attracting-and-assessing-key-talent",
  },
  { slideSource: "static", slideId: "global-footprint" },
  { slideSource: "static", slideId: "our-values" },
  { slideSource: "dynamic", slideId: "consultants" },

  {
    slideSource: "static",
    slideId: "break-slide:bringing-organizations",
  },
];

/*CustomValues.propTypes = {
  project: Project,
};*/
